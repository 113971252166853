import type { TPossibleNull } from '~/types/Shared.types';
import type { IEmitCarouselCases } from '~/features/bk/components/BKCarouselCases/BKCarouselCases.types';

const optionsObserver = (root: TPossibleNull<HTMLElement>) => ({
  root,
  rootMargin: '0px',
  threshold: 0,
});

/** Функция определяет на достаточное ли расстояние произшёл сдвиг рулетки для эмита события скролла
 * @param {Function} emit
 * @param {object} properties - набор св-в для работы функции: рулетки/размер элемента/размер отступов
 **/
export function useEmitScroll(
  emit: IEmitCarouselCases,
  { itemSize, gapInDrop }: { gapInDrop: number; itemSize: Ref<number> },
  direction: 'x' | 'y' = 'x',
): {
  emitScroll: (
    carousel: HTMLElement,
    wrapper: TPossibleNull<HTMLElement>,
    itemCount: number,
    line: HTMLElement,
  ) => () => void;
} {
  function emitScroll(
    carousel: HTMLElement,
    wrapper: TPossibleNull<HTMLElement>,
    itemCount: number,
    line: HTMLElement,
  ) {
    // левая граница карусели, как стартовая точка движения анимации
    const { x: startX, y: startY } = GlobalUtils.DOM.getCoords(carousel);
    // координаты линии
    const { x: lineX, y: lineY } = GlobalUtils.DOM.getCoords(line);

    // размер полной клетки + отступ для щелка
    const controlSize = Math.floor(gapInDrop + itemSize.value);

    // набор точек (x координат) на которые срабатывает эмит
    // первая точка, это преодолеть оставшееся расстояние от места позиционирования палочки до след ячейки, и все
    // последующие учитывают расстояние до первого эмита
    const points: number[] = [];

    // набор предпологаемых элементов карусели, где может распологаться точка
    const itemWherePositionLine: Element[] = [];
    Array.from(carousel.children).forEach((item: Element) => {
      const { x: childX, y: childY } = GlobalUtils.DOM.getCoords(item);

      if (direction === 'x' && childX <= lineX) itemWherePositionLine.push(item);
      if (direction === 'y' && childY <= lineY) itemWherePositionLine.push(item);
    });

    // Крайиний элемент в списке предпологаемых является тем самым, на котором расположена определяющая линия
    // Координаты нужного элемента рулетки

    const lastCoords = GlobalUtils.DOM.getCoords(itemWherePositionLine[itemWherePositionLine.length - 1]);
    // Пройденное расстояние определяющей линии внутри клетки
    const distanceBehindLine = direction === 'x' ? lineX - lastCoords.x : lineY - lastCoords.y;
    // Кол-во пикселей, которое необходимо преодолеть для первого эмита
    const resultStart = (direction === 'x' ? lastCoords.width : lastCoords.height) - distanceBehindLine + gapInDrop;
    points.push(resultStart);

    // Получаем остальные точки для эмита
    for (let i = 0; i < itemCount; i++) {
      const el = controlSize * (i + 1) + resultStart;
      if (direction === 'x' && el > startX) {
        points.push(el);
      }

      if (direction === 'y' && el > startY) {
        points.push(el);
      }
    }

    const observer = new IntersectionObserver(handleIntersection, optionsObserver(wrapper));
    observer.observe(carousel);

    // Уловка для отслеживания движеиня рулетки
    const toggleObserve = () => {
      if (!carousel) return;
      observer.unobserve(carousel);
      observer.observe(carousel);
    };
    function handleIntersection(entries: IntersectionObserverEntry[]) {
      toggleObserve();
      const entriesCoord = direction === 'x' ? entries[0].boundingClientRect.x : entries[0].boundingClientRect.y;

      // Сравниваем пройденное расстояние от стартовой точки
      const deltaCoord = Math.abs((direction === 'x' ? startX : startY) - entriesCoord);

      if (deltaCoord >= points[0]) {
        //   если координата удовлетворяет, то убираем поинт
        points.shift();
        emit('onNextItem');
      }
    }

    return () => observer.unobserve(carousel);
  }

  return {
    emitScroll,
  };
}
